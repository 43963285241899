<template>
  <div style="text-align: left">
    <article>
      <section>
        <div class="container mt-5">
          <div class="mb-3">
            <el-breadcrumb separator-class="el-icon-arrow-right">
              <el-breadcrumb-item :to="{ path: '/' }">Home</el-breadcrumb-item>
              <el-breadcrumb-item :to="{ path: '/categories' }">Categories</el-breadcrumb-item>
              <el-breadcrumb-item :to="{ path: `/category/${$route.params.category_id}/${$route.params.category_name}/sub-categories` }">Sub Categories</el-breadcrumb-item>
               
              <el-breadcrumb-item :to="{ path: `/sub-category/${$route.params.category_id}/${$route.params.category_name}/${$route.params.subCategoryId}/${$route.params.subCategoryName}/products` }">{{ $route.params.category_name }} - Products</el-breadcrumb-item>
              <el-breadcrumb-item>Edit Product</el-breadcrumb-item>
            </el-breadcrumb>
          </div>
          <h1
            class="mb-4"
            style="font-size: 1.1em; font-weight: 600;"
          >Edit Product</h1>
          <div v-loading="isLoading">
            <el-form
              :model="productForm"
              :rules="productRules"
              ref="productForm"
              class="demo-productForm2"
            >
              <div class="row">
                <div class="col-md-6">
                  <label class="mt-3 mb-2">Product Name</label>
                  <el-form-item prop="product_name">
                    <el-input
                      v-model="productForm.product_name"
                      placeholder="Product Name"
                      :disabled="isUploadingFile"
                    ></el-input>
                  </el-form-item>
                </div>
                <div class="col-md-6">
                  <label class="mt-3 mb-2">Product Price</label>
                  <el-form-item prop="product_price">
                    <el-input
                      v-model="productForm.product_price"
                      placeholder="UGX 20000"
                      :disabled="isUploadingFile"
                    ></el-input>
                  </el-form-item>
                </div>
              </div>
              
              <div class="row">
                <div class="col-md-6">
                  <label class="mt-3 mb-2">Number of Products</label>
                  <el-form-item prop="number_of_products">
                    <el-input
                      v-model="productForm.number_of_products"
                      placeholder="100"
                      :disabled="isUploadingFile"
                    ></el-input>
                  </el-form-item>
                </div>
                <div class="col-md-6">
                  <label class="mt-3 mb-2">Product Brief Description</label>
                  <el-form-item prop="product_brief_description">
                    <el-input
                      v-model="productForm.product_brief_description"
                      placeholder="Product Brief Description"
                      :disabled="isUploadingFile"
                    ></el-input>
                  </el-form-item>
                </div>
              </div>

              <div class="row">
                <div class="col-md-12">
                  <label class="mt-3 mb-2">Product Main Image</label><br />
                  <el-form-item>
                    <el-upload
                      class="upload-demo"
                      drag
                      action="''"
                      :on-change="handlePreview"
                      :on-remove="handleRemove"
                      :file-list="fileLists"
                      :auto-upload="false"
                      multiple
                    >
                      <div v-if="!isUploadingFile">
                        <i class="el-icon-upload"></i>
                        <div class="el-upload__text">
                          Drop file here or <em>click to upload</em>
                        </div>
                      </div>
                      <div v-else>
                        <el-progress
                          v-if="imageUploadingProgress != 100"
                          type="circle"
                          :color="progressColors"
                          :percentage="imageUploadingProgress"
                          :width="80"
                          :stroke-width="4"
                        ></el-progress>
                        <el-progress
                          v-else
                          type="circle"
                          :percentage="100"
                          status="success"
                          :width="80"
                          :stroke-width="4"
                        ></el-progress>
                        <div style="font-size: 0.8em; color: rgba(0, 0, 0, 0.8)">
                          {{
                          imageUploadingProgress == 100
                            ? "Uploaded"
                            : "Uploading..."
                        }}
                        </div>
                      </div>
                      <!-- <div class="el-upload__tip" slot="tip">jpg/png files with a size less than 500kb</div> -->
                    </el-upload>
                  </el-form-item>
                </div>
              </div>
              
              <div class="row">
                <div class="col-md-6">
                  <label class="mt-3 mb-2">Product Slide Image One</label><br />
                  <el-form-item>
                    <el-upload class="upload-demo" drag action="''" :on-change="handlePreviewOne" :on-remove="handleRemoveOne"
                      :file-list="fileListsOne" :auto-upload="false">
                      <div v-if="!isUploadingFile">
                        <i class="el-icon-upload"></i>
                        <div class="el-upload__text">
                          Drop file here or <em>click to upload</em>
                        </div>
                      </div>
                      <div v-else>
                        <el-progress v-if="imageUploadingProgress != 100" type="circle" :color="progressColors"
                          :percentage="imageUploadingProgress" :width="80" :stroke-width="4"></el-progress>
                        <el-progress v-else type="circle" :percentage="100" status="success" :width="80"
                          :stroke-width="4"></el-progress>
                        <div style="font-size: 0.8em; color: rgba(0, 0, 0, 0.8)">
                          {{
                            imageUploadingProgress == 100
                            ? "Uploaded"
                            : "Uploading..."
                          }}
                        </div>
                      </div>
                      <!-- <div class="el-upload__tip" slot="tip">jpg/png files with a size less than 500kb</div> -->
                    </el-upload>
                  </el-form-item>
                </div>
                <div class="col-md-6">
                  <label class="mt-3 mb-2">Product Main Image Two</label><br />
                  <el-form-item>
                    <el-upload class="upload-demo" drag action="''" :on-change="handlePreviewTwo" :on-remove="handleRemoveTwo"
                      :file-list="fileListsTwo" :auto-upload="false">
                      <div v-if="!isUploadingFile">
                        <i class="el-icon-upload"></i>
                        <div class="el-upload__text">
                          Drop file here or <em>click to upload</em>
                        </div>
                      </div>
                      <div v-else>
                        <el-progress v-if="imageUploadingProgress != 100" type="circle" :color="progressColors"
                          :percentage="imageUploadingProgress" :width="80" :stroke-width="4"></el-progress>
                        <el-progress v-else type="circle" :percentage="100" status="success" :width="80"
                          :stroke-width="4"></el-progress>
                        <div style="font-size: 0.8em; color: rgba(0, 0, 0, 0.8)">
                          {{
                            imageUploadingProgress == 100
                            ? "Uploaded"
                            : "Uploading..."
                          }}
                        </div>
                      </div>
                      <!-- <div class="el-upload__tip" slot="tip">jpg/png files with a size less than 500kb</div> -->
                    </el-upload>
                  </el-form-item>
                </div>
              </div>

              <div class="row">
                <div class="col-md-6">
                  <label class="mt-3 mb-2">Product Slide Image Three</label><br />
                  <el-form-item>
                    <el-upload class="upload-demo" drag action="''" :on-change="handlePreviewThree" :on-remove="handleRemoveThree"
                      :file-list="fileListsThree" :auto-upload="false">
                      <div v-if="!isUploadingFile">
                        <i class="el-icon-upload"></i>
                        <div class="el-upload__text">
                          Drop file here or <em>click to upload</em>
                        </div>
                      </div>
                      <div v-else>
                        <el-progress v-if="imageUploadingProgress != 100" type="circle" :color="progressColors"
                          :percentage="imageUploadingProgress" :width="80" :stroke-width="4"></el-progress>
                        <el-progress v-else type="circle" :percentage="100" status="success" :width="80"
                          :stroke-width="4"></el-progress>
                        <div style="font-size: 0.8em; color: rgba(0, 0, 0, 0.8)">
                          {{
                            imageUploadingProgress == 100
                            ? "Uploaded"
                            : "Uploading..."
                          }}
                        </div>
                      </div>
                    </el-upload>
                  </el-form-item>
                </div>
                <div class="col-md-6">
                  <label class="mt-3 mb-2">Product Main Image Four</label><br />
                  <el-form-item>
                    <el-upload class="upload-demo" drag action="''" :on-change="handlePreviewFour" :on-remove="handleRemoveFour"
                      :file-list="fileListsFour" :auto-upload="false">
                      <div v-if="!isUploadingFile">
                        <i class="el-icon-upload"></i>
                        <div class="el-upload__text">
                          Drop file here or <em>click to upload</em>
                        </div>
                      </div>
                      <div v-else>
                        <el-progress v-if="imageUploadingProgress != 100" type="circle" :color="progressColors"
                          :percentage="imageUploadingProgress" :width="80" :stroke-width="4"></el-progress>
                        <el-progress v-else type="circle" :percentage="100" status="success" :width="80"
                          :stroke-width="4"></el-progress>
                        <div style="font-size: 0.8em; color: rgba(0, 0, 0, 0.8)">
                          {{
                            imageUploadingProgress == 100
                            ? "Uploaded"
                            : "Uploading..."
                          }}
                        </div>
                      </div>
                      <!-- <div class="el-upload__tip" slot="tip">jpg/png files with a size less than 500kb</div> -->
                    </el-upload>
                  </el-form-item>
                </div>
              </div>

              <div class="row">
                <div class="col-md-12">
                  <label class="mt-3 mb-2">Product Description</label><br />
                  <el-form-item
                    prop="product_description"
                    :disabled="isUploadingFile"
                  >
                    <editor
                      api-key="e5hm05oesjxxiuelp6g7fd8y2rncj6tqnuzkppcogbnf7ra3"
                      :init="{
                        height: 300,
                        menubar: false,
                        plugins: [
                          'a11ychecker','advlist','advcode','advtable','autolink','checklist','export',
                          'lists','link','image','charmap','preview','anchor','searchreplace','visualblocks',
                          'powerpaste','fullscreen','formatpainter','insertdatetime','media','table','help','wordcount'
                        ],
                        toolbar:
                          'undo redo | casechange blocks | bold italic backcolor | \
                          alignleft aligncenter alignright alignjustify | \
                          bullist numlst checklist outdent indent | removeformat | a11ycheck code table help'
                      }"
                      placeholder="Product Description ..."
                      v-model="productForm.product_description"
                    />
                  </el-form-item>
                </div>
              </div>

              <hr />
              <el-form-item class="text-center">
                <div
                  v-if="isUploadingFile"
                  class="d-flex justify-content-center"
                >
                  <ScaleOut />
                </div>
                <el-button
                  type="primary"
                  v-else
                  @click="editProduct('productForm')"
                >Edit Product</el-button>
              </el-form-item>
            </el-form>

            <div class="text-center">
              <div class="text-center">
                <small>© 2022, All rights reserved.</small>
              </div>
              <div class="text-center">
                <!--<small>Design by <a href="https://ovalspace.co" style="color: black;"><strong>Ovalspace</strong></a></small> -->
              </div>
            </div>
          </div>
        </div>
      </section>
    </article>
  </div>
</template>


<script>
import ScaleOut from "@/components/scale-out-component.vue";
import Editor from "@tinymce/tinymce-vue";
export default {
  components: {
    ScaleOut,
    editor: Editor,
  },

  data() {
    return {
      addPackageModal: false,
      selectedFile: null,
      selectedFileOne: null,
      selectedFileTwo: null,
      selectedFileThree: null,
      selectedFileFour: null,
      selectedSlideFile: null,
      selectedSlideFiles: [],
      product: {},
      isUploadingFile: false,
      isLoading: false,
      value: "",
      content: "",
      loading: false,
      loadingError: false,
      imageUploadingProgress: 0,
      progressColors: [
        { color: "#f56c6c", percentage: 25 },
        { color: "#e6a23c", percentage: 50 },
        { color: "#1989fa", percentage: 75 },
        { color: "#6f7ad3", percentage: 100 },
      ],

      productForm: {
        product_name: "",
        product_price: "",
        number_of_products: "",
        product_brief_description: "",
        product_description: "",
      },

      fileLists: [],
      fileListsOne: [],
      fileListsTwo: [],
      fileListsThree: [],
      fileListsFour: [],
      fileLists_many: [],

      productRules: {
        product_name: [
          {
            required: true,
            message: "Product Name is required",
            trigger: "blur",
          },
        ],
        product_price: [
          {
            required: true,
            message: "Product Price is required",
            trigger: "blur",
          },
        ],
        number_of_products: [
          {
            required: true,
            message: "Number of Products is required",
            trigger: "blur",
          },
        ],
        product_brief_description: [
          {
            required: true,
            message: "Product Brief Description is required",
            trigger: "blur",
          },
        ],
      },

      dialogImageUrl: "",
      dialogVisible: false,
      disabled: false,
    };
  },

  mounted() {
    //Code here
    this.getProductById();
  },

  methods: {
    handleClose(done) {
      done();
    },
    handlePreview(file) {
      this.fileLists = [file];
      this.selectedFile = file.raw;
      console.log(this.selectedFile);
    },

    handleChange(file, fileList) {
      this.fileLists_many = fileList;
      console.log(file.raw);
      console.log(this.fileLists_many);
      this.selectedSlideFile = file.raw;
      // this.selectedSlideFiles.push(this.selectedSlideFile);
      this.selectedSlideFiles = this.fileLists_many.map((item) => {
        return item.raw;
      });
      console.log(this.selectedSlideFiles);
    },

    handleRemove() {
      this.selectedFile = null;
    },

    handleRemoveAll(file, fileList) {
      console.log(file);
      console.log(fileList);
      this.fileLists_many.filter((item) => {
        return item.url == `${file.url}`;
      });
    },

    handlePreviewOne(file) {
      this.fileListsOne = [file];
      this.selectedFileOne = file.raw;
      console.log(this.selectedFileOne);
    },

    handleRemoveOne() {
      this.selectedFileOne = null;
    },

    handlePreviewTwo(file) {
      this.fileListsTwo = [file];
      this.selectedFileTwo = file.raw;
      console.log(this.selectedFileTwo);
    },

    handleRemoveTwo() {
      this.selectedFileTwo = null;
    },

    handlePreviewThree(file) {
      this.fileListsThree = [file];
      this.selectedFileThree = file.raw;
      console.log(this.selectedFileThree);
    },

    handleRemoveThree() {
      this.selectedFileThree = null;
    },

    handlePreviewFour(file) {
      this.fileListsFour = [file];
      this.selectedFileFour = file.raw;
      console.log(this.selectedFileFour);
    },

    handleRemoveFour() {
      this.selectedFileFour = null;
    },

    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },

    handleExceed(files, fileList) {
      this.$message.warning(
        `The limit is 4, you selected ${
          files.length
        } files this time, add up to ${files.length + fileList.length} totally`
      );
    },

    beforeRemove(file, fileList) {
      return this.$confirm(`Cancel the transfert of ${file.name} ?`);
    },
    
    handleDownload(file) {
      console.log(file);
    },

    async getProductById() {
      this.isLoading = true;
      this.isLoadingError = false;
      try {
        //TODO: MAKE THIS REQUEST DYNAMIC AND NOT STATIC
        let request = await this.$http.get(
          `products/only/${this.$route.params.product_id}`
        );
        if (request.data.success) {
          this.product = request.data.product;
          this.productForm.product_name = this.product.product_name;
          this.productForm.product_price = this.product.product_price;
          this.productForm.number_of_products = this.product.number_of_products;
          this.productForm.product_brief_description =
            this.product.product_brief_description;
          this.productForm.product_description =
            this.product.product_description;
          this.selectedFile = this.product.main_image;
          this.selectedFileOne = this.product.slide_product_images[0].path;
          this.selectedFileTwo = this.product.slide_product_images[1].path;
          this.selectedFileThree = this.product.slide_product_images[2].path;
          this.selectedFileFour = this.product.slide_product_images[3].path;
          // this.selectedSlideFiles = this.product.slide_product_images.map(
          //   (item) => {
          //     return item.path;
          //   }
          // );
          this.fileLists = [
            {
              name: "product_cover_image",
              url: `${this.product.main_image}`,
            },
          ];

          this.fileListsOne = [{
              name: "slide_image_one",
              url: this.selectedSlideFiles[0],
            },
          ];

          this.fileListsTwo = [{
              name: "slide_image_two",
              url: `${this.selectedSlideFiles[1]}`,
            },
          ];

          this.fileListsThree = [{
              name: "slide_image_three",
              url: `${this.selectedSlideFiles[2]}`,
            },
          ];

          this.fileListsFour = [{
              name: "slide_image_four",
              url: `${this.selectedSlideFiles[3]}`,
            },
          ];
          
          this.fileLists_many = this.product.slide_product_images.map(
            (item, index) => {
              return {
                name: `slide_image_${index}`,
                url: `${item.path}`,
              };
            }
          );
        } else throw "UNEXPECTED_RESPONSE_RECEIVED";
      } catch (error) {
        this.isLoadingError = true;
        //TODO: REPORT THIS TO AN ERROR REPORTING SERVICE
        return this.$notify({
          title: "Operation Failed",
          message: "Unable to fetch product now, please try again",
          type: "error",
        });
      } finally {
        this.isLoading = false;
      }
    },

    async editProduct(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          this.isUploadingFile = true;
          if (this.selectedFile == null) {
            this.$notify({
              title: "Empty",
              message: "Product Cover photo is empty",
              type: "warning",
            });
          }
          
          if (this.selectedFileOne == null) {
            return this.$notify({
              title: "Empty",
              message: "Product photo One is empty",
              type: "warning",
            });
          }

          if (this.selectedFileTwo == null) {
            return this.$notify({
              title: "Empty",
              message: "Product photo Two is empty",
              type: "warning",
            });
          }

          if (this.selectedFileThree == null) {
            return this.$notify({
              title: "Empty",
              message: "Product photo Three is empty",
              type: "warning",
            });
          }

          if (this.selectedFileFour == null) {
            return this.$notify({
              title: "Empty",
              message: "Product photo Four is empty",
              type: "warning",
            });
          }

          try {
            this.isUploadingFile = true;
            const formData = new FormData();
            formData.append("product_name", this.productForm.product_name);
            formData.append("product_price", this.productForm.product_price);
            formData.append(
              "number_of_products",
              this.productForm.number_of_products
            );
            formData.append(
              "product_brief_description",
              this.productForm.product_brief_description
            );
            formData.append(
              "product_description",
              this.productForm.product_description
            );
            formData.append("main_image", this.selectedFile);
            
            formData.append("slide_product_images0", this.selectedFileOne);
            formData.append("slide_product_images1", this.selectedFileTwo);
            formData.append("slide_product_images2", this.selectedFileThree);
            formData.append("slide_product_images3", this.selectedFileFour);

            // for (let i = 0; i < this.selectedSlideFiles.length; i++) {
            //   formData.append(
            //     "slide_product_images" + i,
            //     this.selectedSlideFiles[i]
            //   );
            // }

            let request = await this.$http.patch(
              `products/${this.$route.params.product_id}`,
              formData,
              {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
                onUploadProgress: (progressEvent) => {
                  this.imageUploadingProgress = +(
                    (progressEvent.loaded / progressEvent.total) *
                    100
                  ).toFixed(0);
                },
              }
            );
            if (
              request.data.success &&
              request.data.message == "PRODUCT_UPDATED_SUCCESSFULLY"
            ) {
              this.addPackageModal = false;
              this.$router.push({
                path: `/sub-category/${this.$route.params.category_id}/${this.$route.params.category_name}/${this.$route.params.subCategoryId}/${this.$route.params.subCategoryName}/products`,
              });
              return this.$notify({
                title: "Success",
                message: "Product Updated Successfully",
                type: "success",
              });
            } else throw "UNEXPECTED_RESPONSE";
          } catch (error) {
            if (error.message == "NetworkError") {
              this.isUploadingFile = false;
              return this.$notify({
                title: "Connection failed",
                message: "A network error occurred please try again",
                type: "warning",
              });
            }
            this.isUploadingFile = false;
            return this.$notify({
              title: "Upload Failed",
              message: "Unable to Upload profile picture now, Please try again",
              type: "error",
            });
          } finally {
            this.isUploadingFile = false;
          }
        } else {
          return false;
        }
      });
    },
  },
};
</script>

<style scoped>
.addBtn {
  background-color: green;
  color: white;
}
input[type="text"],
select,
textarea {
  width: 100%;
  padding: 10px 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  resize: vertical;
  background-color: #ffffff;
  font-size: 0.9em;
}

.search_add_section {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.search_by_input {
  width: 400px !important;
}

/* ##Device = Tablets, Ipads (portrait) ##Screen = B/w 768px to 1024px */
@media (min-width: 768px) and (max-width: 1024px) {
}

/* ##Device = Tablets, Ipads (landscape) ##Screen = B/w 768px to 1024px */
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
}

/* ##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px */
@media (min-width: 481px) and (max-width: 767px) {
  .search_add_section {
    display: block;
  }
  .addbtn {
    width: 100%;
    margin-top: 10px;
  }
  .search_by_input {
    width: 100% !important;
  }
}

/* ##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px */
@media (min-width: 320px) and (max-width: 480px) {
  .search_add_section {
    display: block;
  }
  .addbtn {
    width: 100%;
    margin-top: 10px;
  }
  .search_by_input {
    width: 100% !important;
  }
}
</style>

<style>
.safari_dialogs .el-dialog {
  width: 60%;
}

/* ##Device = Tablets, Ipads (portrait) ##Screen = B/w 768px to 1024px */
@media (min-width: 768px) and (max-width: 1024px) {
  .safari_dialogs .el-dialog {
    width: 60%;
  }
}

/* ##Device = Tablets, Ipads (landscape) ##Screen = B/w 768px to 1024px */
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .safari_dialogs .el-dialog {
    width: 70%;
  }
}

/* ##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px */
@media (min-width: 481px) and (max-width: 767px) {
  .safari_dialogs .el-dialog {
    width: 90%;
  }
}

/* ##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px */
@media (min-width: 320px) and (max-width: 480px) {
  .safari_dialogs .el-dialog {
    width: 90%;
  }
}
</style>
